import React, { useState, useEffect } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { isToday, format, parseISO, isBefore, isSameDay, startOfDay } from 'date-fns'
import DatePicker from '../datepicker/datepicker'
import { getLanguage, getLocale } from '../../utils/translation'
import { getZipcode } from '../../actions/zipcodeActions'
import { getZipcodeCalendarForDatePicker } from '../../utils/zipcodes'
import CheckmarkIcon from '../../icons/Checkmark'
import CrossmarkIcon from '../../icons/Crossmark'
import LoadingIndicator from '../productorder/LoadingIndicator'

const pageTranslation = {
    fi: {
        page: {
            date: 'Päivämäärä',
            zipCode: 'Postinumero',
            check: 'Tarkista',
            postalCodeTooShort: 'Postinumero ei ole oikeaa muotoa. Postinumeron tulee olla 5 merkkiä pitkä.',
            postalCodeNumeric: 'Postinumero ei ole oikeaa muotoa. Käytäthän vain numeroita.',
            deliveryPossibleTodayStart: 'Saman päivän toimitus mahdollinen klo',
            deliveryPossibleTodayEnd: ' asti.',
            deliveryNotPossibleToday: 'Saman päivän toimitus ei ole mahdollinen.',
            deliveryPossible: 'Toimitus on mahdollinen valitsemallesi päivälle.',
            deliveryNotPossible: 'Toimitus ei ole mahdollinen valitsemallesi päivälle.',
        },
    },
    en: {
        page: {
            date: 'Date',
            zipCode: 'Postal code',
            check: 'Check',
            postalCodeTooShort: 'Postal code is not in correct format. Postal code must be 5 characters long.',
            postalCodeNumeric: 'Postal code is not in correct format. Please use only numbers.',
            deliveryPossibleTodayStart: 'Same day delivery possible until',
            deliveryPossibleTodayEnd: '.',
            deliveryNotPossibleToday: 'Same day delivery is not possible.',
            deliveryPossible: 'Delivery is possible on the selected date.',
            deliveryNotPossible: 'Delivery is not possible on the selected date.',
        },
    },
}

const PostalCodeSearch = (props) => {
    const { getZipcode, zipcode, zipcode_calendar, zipcode_cutoff } = props

    const lang = getLanguage()
    const selectedTrans = R.path([lang], pageTranslation)
    const trans = R.path(['page'], selectedTrans)
    const locale = getLocale(lang)

    const responseContent = {
        deliveryPossibleToday: {
            text: `${R.path(['deliveryPossibleTodayStart'], trans)} ${
                zipcode_cutoff && zipcode_cutoff.slice(0, 5)
            }${R.path(['deliveryPossibleTodayEnd'], trans)}`,
            icon: <CheckmarkIcon />,
        },
        deliveryNotPossibleToday: { text: `${R.path(['deliveryNotPossibleToday'], trans)}`, icon: <CrossmarkIcon /> },
        deliveryPossible: { text: `${R.path(['deliveryPossible'], trans)}`, icon: <CheckmarkIcon /> },
        deliveryNotPossible: { text: `${R.path(['deliveryNotPossible'], trans)}`, icon: <CrossmarkIcon /> },
    }

    const [date, setDate] = useState(new Date())
    const [postalCode, setPostalCode] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [response, setResponse] = useState(undefined)
    const [validationError, setValidationError] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)

    const availableDates = getZipcodeCalendarForDatePicker(zipcode_calendar, zipcode_cutoff)

    useEffect(() => {
        if (!submitted) return
        setSubmitted(false)
        setIsLoading(false)

        if (isToday(date))
            return isBefore(new Date(), parseISO(`${format(new Date(), 'yyyy-MM-dd')}T${zipcode_cutoff}`))
                ? setResponse(responseContent.deliveryPossibleToday)
                : setResponse(responseContent.deliveryNotPossibleToday)

        availableDates.some((availableDate) => isSameDay(startOfDay(date), startOfDay(new Date(availableDate))))
            ? setResponse(responseContent.deliveryPossible)
            : setResponse(responseContent.deliveryNotPossible)
    }, [zipcode, zipcode_calendar, zipcode_cutoff])

    const handleSubmit = (e) => {
        e.preventDefault()
        setResponse(undefined)

        if (validateForm()) {
            setIsLoading(true)
            getZipcode(postalCode)
            setSubmitted(true)
        }
    }

    const validateForm = () => {
        if (postalCode.length < 5) return setValidationError(R.path(['postalCodeTooShort'], trans))
        if (!/^\d+$/.test(postalCode)) return setValidationError(R.path(['postalCodeNumeric'], trans))
        return true
    }

    return (
        <form noValidate onSubmit={handleSubmit}>
            <input
                id="postalCodeInput"
                maxLength={5}
                className="postal_code__input"
                type="text"
                placeholder={R.path(['zipCode'], trans)}
                value={postalCode}
                onChange={(e) => {
                    setValidationError(undefined)
                    setPostalCode(e.target.value)
                }}
            />
            <div className="delivery_info__date_and_button__container">
                <DatePicker
                    id="postalCodeDatePicker"
                    locale={locale}
                    dateFormat={'dd.MM.yyyy'}
                    className="postal_code__input"
                    placeholderText={R.path(['date'], trans)}
                    selected={date}
                    onChange={(selectedDate) => setDate(selectedDate)}
                    enableTabLoop={false}
                />
                <button type="submit" className="delivery_info__button" disabled={isLoading}>
                    {R.path(['check'], trans)}
                </button>
            </div>
            <p className="postal_code_search_response__error">{validationError}</p>
            <div className="postal_code_search_response__container">
                {isLoading && <LoadingIndicator overwrite />}
                {!isLoading && response && (
                    <React.Fragment>
                        {response.icon}
                        <p>{response.text}</p>
                    </React.Fragment>
                )}
            </div>
        </form>
    )
}

const mapStateToProps = (state) => ({
    zipcode_cutoff: R.path(['zipcode', 'cutoff'], state),
    zipcode: R.path(['zipcode', 'delivery_zipcode'], state),
    zipcode_calendar: R.path(['zipcode', 'calendar'], state),
})

const mapDispatchToProps = (dispatch) => ({
    getZipcode: (zipcode) => dispatch(getZipcode(zipcode)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PostalCodeSearch)
