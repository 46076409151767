import * as R from 'ramda'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { addRibbonContent } from '../../actions/formActions'

import Form from 'react-validation/build/form'

import { saveRibbonContent } from '../../actions/cartActions'

import { getLanguage } from '../../utils/translation'
import { isForeignProductByProduct } from '../../utils/ProductTypes'
import BottomNavigationBar from '../bottom-navigation-bar/BottomNavigationBar'

const pageTranslation = {
    fi: {
        page: {
            ribbonTextHeader: 'Nauhateksti',
            ribbonTextArea: 'Värssy tai muistosanat',
            names: 'Nimi/ nimet',
        },
    },
    en: {
        page: {
            ribbonTextHeader: 'Ribbon text',
            ribbonTextArea: 'Verse or greeting',
            names: 'Name/ names',
        },
    },
}

class FormRibbon extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ribbonWords: '',
            ribbonNames: '',
        }

        this.handle_ribbonWords_change = this.handle_ribbonWords_change.bind(this)
        this.handle_ribbonNames_change = this.handle_ribbonNames_change.bind(this)

        const { formData, history, cartProducts, lang, cc } = this.props

        this.setState({
            ribbonWords: R.path(['ribbonWords'], formData),
            ribbonNames: R.path(['ribbonNames'], formData),
        })

        // No ribbon or condolences products selection for international orders
        if (isForeignProductByProduct(R.head(cartProducts))) {
            history.push(`/${lang}/${cc}`)
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    saveToStore(event) {
        const { addRibbonContent } = this.props
        const { ribbonWords, ribbonNames } = this.state
        addRibbonContent(ribbonWords, ribbonNames)
    }

    handle_ribbonWords_change(event) {
        this.setState({ ribbonWords: event.target.value }, this.saveToStore)
    }

    handle_ribbonNames_change(event) {
        this.setState({ ribbonNames: event.target.value }, this.saveToStore)
    }

    submitRibbonContent(event) {
        event.preventDefault()
        const { saveRibbonContent } = this.props
        saveRibbonContent()
    }

    render() {
        const { lang, cc, cartLoading } = this.props

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        return (
            <div className="productOrder__contentHolder">
                <Form
                    ref={(c) => {
                        this.form = c
                    }}
                    className="pure-form pure-form-stacked productOrder__form"
                >
                    <fieldset>
                        <div className="pure-g">
                            <div className="pure-u-20-24">
                                <h2 className="order__headline">{R.path(['ribbonTextHeader'], trans)}</h2>
                            </div>
                            <div className="pure-u-4-24 alignRight form__indicator">
                                <span></span>
                            </div>
                        </div>

                        <div className="input-group form__field">
                            <label htmlFor="ribbonWords">{R.path(['ribbonTextArea'], trans)}</label>
                            <div className="productOrder__attachedMessage__container">
                                <textarea
                                    maxLength="99"
                                    id="ribbonWords"
                                    className="productOrder__attachedMessage"
                                    value={this.state.ribbonWords}
                                    onChange={this.handle_ribbonWords_change}
                                    placeholder=""
                                ></textarea>
                                <span className="productOrder__attachedMessage__counter">
                                    {this.state.ribbonWords.length}/99
                                </span>
                            </div>
                        </div>

                        <div className="input-group form__field">
                            <label htmlFor="ribbonNames">{R.path(['names'], trans)}</label>
                            <div className="productOrder__attachedMessage__container">
                                <textarea
                                    maxLength="99"
                                    id="ribbonNames"
                                    className="productOrder__attachedMessage"
                                    value={this.state.ribbonNames}
                                    onChange={this.handle_ribbonNames_change}
                                    placeholder=""
                                ></textarea>
                                <span className="productOrder__attachedMessage__counter">
                                    {this.state.ribbonNames.length}/99
                                </span>
                            </div>
                        </div>
                    </fieldset>
                    <BottomNavigationBar
                        backLink={`/${lang}/${cc}/order/recipient-info`}
                        handleContinue={this.submitRibbonContent.bind(this)}
                        loading={cartLoading}
                    />
                </Form>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        formData: state.form,
        order: state.order,
        condolencesSelected: R.path(['order', 'condolences'], state),
        lang: R.path(['lang', 'lang'], state),
        cc: R.path(['lang', 'db'], state),
        cartProducts: R.pathOr([], ['cart', 'items', 'products'], state),
        cartLoading: R.path(['cart', 'loading'], state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addRibbonContent: (ribbonWords, ribbonNames) => dispatch(addRibbonContent(ribbonWords, ribbonNames)),
        saveRibbonContent: () => dispatch(saveRibbonContent()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormRibbon))
