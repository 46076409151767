import * as R from 'ramda'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { addCondolencesContent } from '../../actions/formActions'

import { name, required } from '../../utils/ValidationRules.jsx'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'

import { getLanguage } from '../../utils/translation'

import { saveCondolencesContent } from '../../actions/cartActions'
import { isForeignProductByProduct } from '../../utils/ProductTypes'
import BottomNavigationBar from '../bottom-navigation-bar/BottomNavigationBar'

const pageTranslation = {
    fi: {
        page: {
            condolenceCardHeader: 'Adressi',
            greeting: 'Muistosanat',
            greetingTextArea:
                'Adresseihin on mahdollista lisätä muistosanat eli värssy. Adresseissa on myös valmiita värssyjä, joten tämä kenttä on mahdollista jättää myös tyhjäksi',
            nameDeceasedHeader: 'Vainajan nimi',
            deceasedTextArea: 'Adressiin tekstataan aina vainajan nimi',
            senderName: 'Lähettäjien nimet',
            senderNameTextArea: 'Adressin lähettäjien, henkilöiden, yrityksen tai yhteisön nimi/nimet',
        },
    },
    en: {
        page: {
            condolenceCardHeader: 'Card of condolence',
            greeting: 'Greeting',
            greetingTextArea:
                'Add your own verse or greeting to the card of condolence. You can also leave this field empty and the delivering flower shop will add a pre-filled card of condolence to the delivery.',
            nameDeceasedHeader: 'Name of the deceased',
            deceasedTextArea: 'The name of the deceased will be added to the card of condolence',
            senderName: 'Name of sender',
            senderNameTextArea: 'The name(s) of the sender(s), company’s or community’s name',
        },
    },
}

class FormCondolences extends Component {
    constructor(props) {
        super(props)
        this.state = {
            condolenceWords: '',
            condolenceMortName: '',
            condolenceSenderNames: '',
        }

        this.handle_condolenceWords_change = this.handle_condolenceWords_change.bind(this)
        this.handle_condolenceMortName_change = this.handle_condolenceMortName_change.bind(this)
        this.handle_condolenceSenderNames_change = this.handle_condolenceSenderNames_change.bind(this)

        const { formData, history, cartProducts, lang, cc } = this.props

        this.setState({
            condolenceWords: R.path(['condolenceWords'], formData),
            condolenceMortName: R.path(['condolenceMortName'], formData),
            condolenceSenderNames: R.path(['condolenceSenderNames'], formData),
        })

        // No ribbon or condolences products selection for international orders
        if (isForeignProductByProduct(R.head(cartProducts))) {
            history.push(`/${lang}/${cc}`)
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    saveToStore(event) {
        const { addCondolencesContent } = this.props
        const { condolenceWords, condolenceMortName, condolenceSenderNames } = this.state
        addCondolencesContent(condolenceWords, condolenceMortName, condolenceSenderNames)
    }

    handle_condolenceWords_change(event) {
        this.setState({ condolenceWords: event.target.value }, this.saveToStore)
    }

    handle_condolenceMortName_change(event) {
        this.setState({ condolenceMortName: event.target.value }, this.saveToStore)
    }

    handle_condolenceSenderNames_change(event) {
        this.setState({ condolenceSenderNames: event.target.value }, this.saveToStore)
    }

    submitCondolencesContent(event) {
        event.preventDefault()
        const { saveCondolencesContent } = this.props
        saveCondolencesContent()
    }

    render() {
        const { lang, cc, cartLoading, ribbonSelected } = this.props
        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        const backLink = ribbonSelected ? `/${lang}/${cc}/order/ribbon` : `/${lang}/${cc}/order/recipient-info`

        return (
            <div className="productOrder__contentHolder">
                <Form
                    ref={(c) => {
                        this.form = c
                    }}
                    className="pure-form pure-form-stacked productOrder__form"
                >
                    <fieldset>
                        <div className="pure-g">
                            <div className="pure-u-20-24">
                                <h2 className="order__headline">{R.path(['condolenceCardHeader'], trans)}</h2>
                            </div>
                            <div className="pure-u-4-24 alignRight form__indicator">
                                <span></span>
                            </div>
                        </div>

                        <div className="input-group form__field">
                            <label htmlFor="condolenceWords">{R.path(['greeting'], trans)}</label>
                            <div className="productOrder__attachedMessage__container">
                                <textarea
                                    maxLength="255"
                                    id="condolenceWords"
                                    className="productOrder__attachedMessage"
                                    value={this.state.condolenceWords}
                                    onChange={this.handle_condolenceWords_change}
                                    placeholder={R.path(['greetingTextArea'], trans)}
                                />
                                <span className="productOrder__attachedMessage__counter">
                                    {this.state.condolenceWords.length}/255
                                </span>
                            </div>
                        </div>

                        <div className="input-group form__field">
                            <label htmlFor="condolenceMortName">
                                {R.path(['nameDeceasedHeader'], trans)}
                                <sup>*</sup>
                            </label>
                            <Input
                                onChange={this.handle_condolenceMortName_change}
                                placeholder={R.path(['deceasedTextArea'], trans)}
                                id="condolenceMortName"
                                errorclassname="is-invalid-input"
                                type="text"
                                containerclassname=""
                                value={this.state.condolenceMortName}
                                name="condolenceMortName"
                                validations={[required, name]}
                                lang={lang}
                            />
                        </div>

                        <div className="input-group form__field">
                            <label htmlFor="condolenceSenderNames">
                                {R.path(['senderName'], trans)}
                                <sup>*</sup>
                            </label>
                            <Input
                                onChange={this.handle_condolenceSenderNames_change}
                                placeholder={R.path(['senderNameTextArea'], trans)}
                                id="condolenceSenderNames"
                                errorclassname="is-invalid-input"
                                type="text"
                                containerclassname=""
                                value={this.state.condolenceSenderNames}
                                name="condolenceSenderNames"
                                validations={[required, name]}
                                lang={lang}
                            />
                        </div>
                    </fieldset>
                    <BottomNavigationBar
                        backLink={backLink}
                        handleContinue={this.submitCondolencesContent.bind(this)}
                        loading={cartLoading}
                    />
                </Form>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        formData: state.form,
        order: state.order,
        lang: R.path(['lang', 'lang'], state),
        cc: R.path(['lang', 'db'], state),
        cartProducts: R.pathOr([], ['cart', 'items', 'products'], state),
        cartLoading: R.path(['cart', 'loading'], state),
        ribbonSelected: R.path(['order', 'ribbon'], state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addCondolencesContent: (condolenceWords, condolenceMortName, condolenceSenderNames) =>
            dispatch(addCondolencesContent(condolenceWords, condolenceMortName, condolenceSenderNames)),
        saveCondolencesContent: () => dispatch(saveCondolencesContent()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormCondolences))
